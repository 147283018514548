module.exports = {
  pathPrefix: `/`,
  url: `https://science-and-leadership.com/`,
  title: `Science & Leadership`,
  titleTemplate: `%s — Science & Leadership`,
  description: `We bring science to business.`,
  image: `/images/meta-image-default.png`, // Path to the default meta image in `static` folder
  siteLanguage: `en`, // Language tag on <html> element

  // Web App Manifest
  favicon: `src/images/favicon.png`, // Used for manifest favicon generation
  shortName: `S&L`, // shortname for manifest. *Must* be shorter than 12 characters
  themeColor: `#b9c8df`,
  backgroundColor: `#b9c8df`,

  // schema.org JSONLD
  headline: `We bring science to business.`,
  author: `Karolien Notebaert`,

  // Google & Twitter
  googleAnalyticsId: `UA-53671842-2`, // Google Analytics ID
  twitter: `@karonotebaert`, // Twitter username
}
