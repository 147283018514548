// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-books-pilgrim-who-trained-her-monkey-jsx": () => import("./../../../src/pages/books/pilgrim-who-trained-her-monkey.jsx" /* webpackChunkName: "component---src-pages-books-pilgrim-who-trained-her-monkey-jsx" */),
  "component---src-pages-expertise-index-jsx": () => import("./../../../src/pages/expertise/index.jsx" /* webpackChunkName: "component---src-pages-expertise-index-jsx" */),
  "component---src-pages-expertise-keynotes-jsx": () => import("./../../../src/pages/expertise/keynotes.jsx" /* webpackChunkName: "component---src-pages-expertise-keynotes-jsx" */),
  "component---src-pages-expertise-retreats-jsx": () => import("./../../../src/pages/expertise/retreats.jsx" /* webpackChunkName: "component---src-pages-expertise-retreats-jsx" */),
  "component---src-pages-expertise-trainings-jsx": () => import("./../../../src/pages/expertise/trainings.jsx" /* webpackChunkName: "component---src-pages-expertise-trainings-jsx" */),
  "component---src-pages-imprint-jsx": () => import("./../../../src/pages/imprint.jsx" /* webpackChunkName: "component---src-pages-imprint-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-people-jsx": () => import("./../../../src/pages/people.jsx" /* webpackChunkName: "component---src-pages-people-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */)
}

