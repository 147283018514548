/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const GoogleAnalytics = require('react-ga')
const UniversalCookies = require('universal-cookie')
const website = require('./config/website')

// eslint-disable-next-line new-cap
const cookies = new UniversalCookies.default()

const COOKIE_NAME = 'cookie-consent'

/**
 * Initialize Google Analytics
 */
exports.onClientEntry = () => {
  if (cookies.get(COOKIE_NAME) === `true`) {
    GoogleAnalytics.initialize(website.googleAnalyticsId)
  }
}

/**
 * Force-reload entire page if there's new content
 */
exports.onServiceWorkerUpdateReady = () => {
  window.location.reload()
}

exports.onRouteUpdate = ({ location }) => {
  if (cookies.get(COOKIE_NAME) === `true` && GoogleAnalytics.ga) {
    GoogleAnalytics.set({ page: location.pathname, anonymizeIp: true })
    GoogleAnalytics.pageview(location.pathname)
  }
}
